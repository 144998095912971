import React from 'react';
import PostFun from '../components/PostFun';
import image1 from '../img/6-18-1.jpg';
import image2 from '../img/6-18-2.jpg';

function Post6_18() {
  const title = "汤普森&索菲亚的恋爱小记录";
  const date = "2023年6月18日";
  const images = [image1,image2];
  const descriptionParagraphs = [
    "我这朵独一无二的玫瑰也有了属于我自己的b612星球",
    "我相信安东尼也知道这一次故事的结局没有了遗憾",
    "小王子&玫瑰都懂得了如何去爱",
    "仪式是什么？这也是经常被遗忘的事情，它使得某个日子区别于其他日子，某个时刻区别于其他时刻",
  ];

  return <PostFun title={title} date={date} imageSources={images} descriptionParagraphs={descriptionParagraphs} />;
}

export default Post6_18;