import React from 'react';
import PostFun from '../components/PostFun';
import image1 from '../img/10-6-1.jpg';

function Post10_6() {
  const title = "汤普森&索菲亚的恋爱小记录";
  const date = "2023年10月6日";
  const images = [image1];
  const descriptionParagraphs = [
    "呜呜呜，第一次吃宝贝给我做的饭，很美味，很惊喜，很温暖，消灭干净！",
  ];

  return <PostFun title={title} date={date} imageSources={images} descriptionParagraphs={descriptionParagraphs} />;
}

export default Post10_6;