import React from 'react';
import PostFun from '../components/PostFun';
import image1 from '../img/2-14-1.jpg';
import image2 from '../img/2-14-2.jpg';
import image3 from '../img/2-14-3.jpg';
import image4 from '../img/2-14-4.jpg';

function Post2_14() {
  const title = "汤普森&索菲亚的恋爱小记录";
  const date = "2023年2月2日";
  const images = [image1,image2,image3,image4];
  const descriptionParagraphs = [
    "在一段健康的恋爱里，惊喜制造方和体验方所感受到的幸福从来都不分胜负",
    "一月就计划好的雪花酥，也在你打开盒子时脸上露出的小小酒窝里将那份甜蜜回寄给了我。",
  ];

  return <PostFun title={title} date={date} imageSources={images} descriptionParagraphs={descriptionParagraphs} />;
}

export default Post2_14;