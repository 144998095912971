import React from 'react';
import PostFun from '../components/PostFun';
import image1 from '../img/8-20-1.jpg';

function Post8_20() {
  const title = "汤普森&索菲亚的恋爱小记录";
  const date = "2023年8月20日";
  const images = [image1];
  const descriptionParagraphs = [
    "我从小就被教育男孩子要坚强，男儿有泪不轻弹。我虽然成长在一个幸福的家庭环境里，但我也不能很自然很舒服的表达自己的负面情绪，也不能随意的流眼泪。但是宝贝一直鼓励我，开导我说出情绪，也一直安慰我，支持我。这种能够把情绪全部表达的感觉真的好好，宝贝给我提供的好多的安全感和力量感。",
    "谢谢宝贝，有宝贝真好",
  ];

  return <PostFun title={title} date={date} imageSources={images} descriptionParagraphs={descriptionParagraphs} />;
}

export default Post8_20;