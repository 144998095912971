import React from 'react';
import PostFun from '../components/PostFun';
import image1 from '../img/2-2-1.jpg';
import image2 from '../img/2-2-2.jpg';
import image3 from '../img/2-2-3.jpg';

function Post2_2() {
  const title = "汤普森&索菲亚的恋爱小记录";
  const date = "2023年2月2日";
  const images = [image1,image2,image3];
  const descriptionParagraphs = [
    "流浪地球，一次跨域时区的旅行，一次充满爱意的惊喜。在2023年的元宵节，为我的宝贝煮上汤圆，热气腾腾，雾散云开，睁眼看到拿着拍立得的汤普森。",
  ];

  return <PostFun title={title} date={date} imageSources={images} descriptionParagraphs={descriptionParagraphs} />;
}

export default Post2_2;