import React from 'react';
import PostFun from '../components/PostFun';
import image1 from '../img/9-21-1.jpg';
import image2 from '../img/9-21-2.jpg';
import image3 from '../img/9-21-3.jpg';

function Post9_21() {
  const title = "汤普森&索菲亚的恋爱小记录";
  const date = "2023年9月21日";
  const images = [image1,image2,image3];
  const descriptionParagraphs = [
    "宝贝，生日快乐",
    "希望宝贝的20岁，充满快乐与幸福，希望宝贝的这一年，收获满满，取得进步。也希望宝贝在我的陪伴下，探索爱情，探索自我，追求真爱，追求本心。这是我陪宝贝过的第一个生日，希望宝贝能够喜欢。你的男朋友并不是个完美的修狗，他有时候会惹宝贝生气，会让宝贝不开心，但是这都不是修狗有意的，修狗也很难过，很内疚，也希望宝贝多多包容你的小狗呀，毕竟要陪宝贝一辈子的呢，小狗也会慢慢进步的哦。",
  ];

  return <PostFun title={title} date={date} imageSources={images} descriptionParagraphs={descriptionParagraphs} />;
}

export default Post9_21;