import React from 'react';
import PostFun from '../components/PostFun';
import image1 from '../img/9-23-1.jpg';
import image2 from '../img/9-23-2.jpg';

function Post9_23() {
  const title = "汤普森&索菲亚的恋爱小记录";
  const date = "2023年9月23日";
  const images = [image1,image2];
  const descriptionParagraphs = [
    "500多英里的旅程，穿过大烟山的树林和绿荫，山路蜿蜒，道路曲折，这里是阿巴拉契亚的最南端，也是美国东部森林最后的荫蔽。每越过一段盘山公路，就会有新的景色迎接我们，直到来到泰坦尼克号博物馆的脚下，开始我们的浪漫之旅。我们见识到了很多一百年前的物件和船舱设计，也体验了冰水的温度，还很惊喜地看到了交响乐队的表演。",
    "浪漫不变，我心永恒。",
  ];

  return <PostFun title={title} date={date} imageSources={images} descriptionParagraphs={descriptionParagraphs} />;
}

export default Post9_23;