import React from 'react';
import '../styles/Sky.css';

function Sky() {
    
  return (
    <div className="sky-container">
      <div className="sky-top">
        <h1 className="sky-title">汤普森&索菲亚的恋爱小记录</h1>
        <nav className="sky-menu">
          <a href="/">苍穹首页</a>
          <a href="/photo">星汉点滴</a>
          <a href="/time">时光流星</a>
        </nav>
      </div>
      <div className="sky-main-content">
        <p >Year in</p>
        <p >Year out</p>
      </div>
    </div>
  );
}

export default Sky;
