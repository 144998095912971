import React from 'react';
import PostFun from '../components/PostFun';
import image1 from '../img/3-22-1.jpg';
import image2 from '../img/3-22-2.jpg';

function Post3_22() {
  const title = "汤普森&索菲亚的恋爱小记录";
  const date = "2023年3月22日";
  const images = [image1,image2];
  const descriptionParagraphs = [
    "虽然错过了出海观鲸，两个人手牵手漫步在波士顿街头也是同等的浪漫",
  ];

  return <PostFun title={title} date={date} imageSources={images} descriptionParagraphs={descriptionParagraphs} />;
}

export default Post3_22;