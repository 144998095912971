import React from 'react';
import PostFun from '../components/PostFun';
import image1 from '../img/7-19-1.jpg';
import image2 from '../img/7-19-2.jpg';
import image3 from '../img/7-19-3.jpg';
import image4 from '../img/7-19-4.jpg';

function Post7_19() {
  const title = "汤普森&索菲亚的恋爱小记录";
  const date = "2023年7月19日";
  const images = [image1,image2,image3,image4];
  const descriptionParagraphs = [
    "祝我们两百天快乐呀",
    "CBD旁，琳琅海鲜端上餐桌",
    "嘉陵江畔，纪念日浪漫走过18梯",
    "宝贝是用心的画家，小王子和全世界的我爱你一起见证我们的爱情",
  ];

  return <PostFun title={title} date={date} imageSources={images} descriptionParagraphs={descriptionParagraphs} />;
}

export default Post7_19;