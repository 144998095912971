
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Sky from './pages/Sky';
import Photo from './pages/Photo';
import Time from './pages/Time';
import Post8_3 from './pages/Post';
import Post8_8 from './pages/Post8_8';
import Post8_20 from './pages/Post8_20';
import Post9_1 from './pages/Post9_1';
import Post9_21 from './pages/Post9_21';
import Post9_23 from './pages/Post9_23';
import Post10_6 from './pages/Post10_6';
import Post10_7 from './pages/Post10_7';
import Post10_28 from './pages/Post10_28';
import Post12_18 from './pages/Post12_18';
import Post1_1 from './pages/Post1_1';
import Post2_2 from './pages/Post2_2';
import Post2_14 from './pages/Post2_14';
import Post3_22 from './pages/Post3_22';
import Post4_29 from './pages/Post4_29';
import Post5_3 from './pages/Post5_3';
import Post5_20 from './pages/Post5_20';
import Post6_18 from './pages/Post6_18';
import Post6_29 from './pages/Post6_29';
import Post7_9 from './pages/Post7_9';
import Post7_12 from './pages/Post7_12';
import Post7_19 from './pages/Post7_19';
import Post7_21 from './pages/Post7_21';
import Post7_28 from './pages/Post7_28';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route index element={<Sky />}/>
          <Route path='/photo' element={<Photo />}/>
          <Route path='/time' element={<Time />}/>
          <Route path='/post8_3' element={<Post8_3 />}/>
          <Route path='/post8_8' element={<Post8_8 />}/>
          <Route path='/post8_20' element={<Post8_20 />}/>
          <Route path='/post9_1' element={<Post9_1 />}/>
          <Route path='/post9_21' element={<Post9_21 />}/>
          <Route path='/post9_23' element={<Post9_23 />}/>
          <Route path='/post10_6' element={<Post10_6 />}/>
          <Route path='/post10_7' element={<Post10_7 />}/>
          <Route path='/post10_28' element={<Post10_28 />}/>
          <Route path='/post12_18' element={<Post12_18 />}/>
          <Route path='/post1_1' element={<Post1_1 />}/>
          <Route path='/post2_2' element={<Post2_2 />}/>
          <Route path='/post2_14' element={<Post2_14 />}/>
          <Route path='/post3_22' element={<Post3_22 />}/>
          <Route path='/post4_29' element={<Post4_29 />}/>
          <Route path='/post5_3' element={<Post5_3 />}/>
          <Route path='/post5_20' element={<Post5_20 />}/>
          <Route path='/post6_18' element={<Post6_18 />}/>
          <Route path='/post6_29' element={<Post6_29 />}/>
          <Route path='/post7_9' element={<Post7_9 />}/>
          <Route path='/post7_12' element={<Post7_12 />}/>
          <Route path='/post7_19' element={<Post7_19 />}/>
          <Route path='/post7_21' element={<Post7_21 />}/>
          <Route path='/post7_28' element={<Post7_28 />}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
