import React from 'react';
import PostFun from '../components/PostFun';
import image1 from '../img/12-18-1.jpg';

function Post12_18() {
  const title = "汤普森&索菲亚的恋爱小记录";
  const date = "2023年12月18日";
  const images = [image1];
  const descriptionParagraphs = [
    "拥有一个电动变形金刚是我小时候的梦想，它会说话会自己动，有着和原版相似的声音，谢谢宝贝实现我小时候的梦想呀。在宝贝面前我永远都是小朋友，都是听话的小狗。",
    "在选礼物这件事情上，宝贝做得比我好太多啦，向宝贝学习",
  ];

  return <PostFun title={title} date={date} imageSources={images} descriptionParagraphs={descriptionParagraphs} />;
}

export default Post12_18;