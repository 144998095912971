import React from 'react';
import PostFun from '../components/PostFun';
import image1 from '../img/6-29-1.jpg';
import image2 from '../img/6-29-2.jpg';
import image3 from '../img/6-29-3.jpg';

function Post6_29() {
  const title = "汤普森&索菲亚的恋爱小记录";
  const date = "2023年6月29日";
  const images = [image1,image2,image3];
  const descriptionParagraphs = [
    "二分之一是不完整的",
    "君豪大酒店，布拉诺餐厅，我带着你，去重走我成长路上的酒店与餐厅，每一站，都给这些地方赋予了新的幸福记忆",
    "一个多小时的激情，却远不够述说我们的热烈；布拉诺的惠灵顿，却无法烹饪出爱情的美味",
    "祝我们半年快乐呀",
  ];

  return <PostFun title={title} date={date} imageSources={images} descriptionParagraphs={descriptionParagraphs} />;
}

export default Post6_29;