import React from 'react';
import '../styles/Post.css'; // Updated CSS import
import image1 from '../img/8-8-1.jpg';
import image2 from '../img/8-8-2.jpg';
import image4 from '../img/8-8-3.jpg';

function Post8_8() { // Updated component name
    
  return (
    <div className="post-container"> 
      <div className="post-top">
        <h1 className="post-title">汤普森&索菲亚的恋爱小记录</h1>
        <nav className="post-menu"> 
          <a href="/">苍穹首页</a>
          <a href="/photo">星汉点滴</a>
          <a href="/time">时光流星</a>
        </nav>
      </div>
      <div className="post-main-content">
        <h >2023年8月8日</h>
        <img src={image1} alt="Description" />
        <img src={image2} alt="Description" />
        <img src={image4} alt="Description" />
      </div>
      <div className="post-description">
        <p>
        坚固的沙粒，一定还留着我的回忆，那些老朋友一定努力的爬上来，看着我们这般幸福模样；激情的浪花，一定还在讲着重复的故事，它们用力拍打，生怕不能沁人心脾；奔跑的海风，一定还在追逐远方的梦想，它已经陪着我们一起，从地球的另一端返回。
        </p>
        <p>
        汤普森的计划：海边微醺
        </p>
        <p>
        汤普森的表现：半瓶就睡
        </p>
      </div>
    </div>
  );
}

export default Post8_8; 
