import React from 'react';
import '../styles/Photo.css';
import image8_8 from '../img/8-8-1.jpg';
import image8_3 from '../img/8-3-4.jpg';
import image8_20 from '../img/8-20-1.jpg';
import image9_1 from '../img/8-3-2.jpg';
import image9_21 from '../img/9-21-2.jpg';
import image9_23 from '../img/9-23-1.jpg';
import image10_6 from '../img/10-6-1.jpg';
import image10_7 from '../img/10-7-1.jpg';
import image10_28 from '../img/10-28-1.jpg';
import image12_18 from '../img/12-18-1.jpg';
import image1_1 from '../img/1-1-1.jpg';
import image2_2 from '../img/2-2-1.jpg';
import image2_14 from '../img/2-14-4.jpg';
import image3_22 from '../img/3-22-1.jpg';
import image4_29 from '../img/4-29-1.jpg';
import image5_3 from '../img/5-3-1.jpg';
import image5_20 from '../img/5-20-1.jpg';
import image6_18 from '../img/6-18-1.jpg';
import image6_29 from '../img/6-29-1.jpg';
import image7_9 from '../img/7-9-2.jpg';
import image7_12 from '../img/7-12-1.jpg';
import image7_19 from '../img/7-19-1.jpg';
import image7_21 from '../img/7-21-1.jpg';
import image7_28 from '../img/7-28-1.jpg';
function Photo() { 
  return (
    <div className="photo-container"> 
      <div className="photo-top">
        <h1 className="photo-title">汤普森&索菲亚的恋爱小记录</h1>
        <nav className="photo-menu"> 
          <a href="/">苍穹首页</a>
          <a href="/photo">星汉点滴</a>
          <a href="/time">时光流星</a>
        </nav>
      </div>
      <div className="photo-main-content">
        <a href="/post8_8"><img src={image8_8} alt="Sample 1" /></a>
        <a href="/post8_3"><img src={image8_3} alt="Sample 2" /></a>
        <a href="/post8_20"><img src={image8_20} alt="Sample 3" /></a>
        <a href="/post9_1"><img src={image9_1} alt="Sample 3" /></a>
        <a href="/post9_21"><img src={image9_21} alt="Sample 3" /></a>
        <a href="/post9_23"><img src={image9_23} alt="Sample 3" /></a>
        <a href="/post10_6"><img src={image10_6} alt="Sample 3" /></a>
        <a href="/post10_7"><img src={image10_7} alt="Sample 3" /></a>
        <a href="/post10_28"><img src={image10_28} alt="Sample 3" /></a>
        <a href="/post12_18"><img src={image12_18} alt="Sample 3" /></a>
        <a href="/post1_1"><img src={image1_1} alt="Sample 3" /></a> 
        <a href="/post2_2"><img src={image2_2} alt="Sample 3" /></a>
        <a href="/post2_14"><img src={image2_14} alt="Sample 3" /></a> 
        <a href="/post3_22"><img src={image3_22} alt="Sample 3" /></a>         
        <a href="/post4_29"><img src={image4_29} alt="Sample 3" /></a>
        <a href="/post5_3"><img src={image5_3} alt="Sample 3" /></a> 
        <a href="/post5_20"><img src={image5_20} alt="Sample 3" /></a>  
        <a href="/post6_18"><img src={image6_18} alt="Sample 3" /></a> 
        <a href="/post6_29"><img src={image6_29} alt="Sample 3" /></a>
        <a href="/post7_9"><img src={image7_9} alt="Sample 3" /></a>
        <a href="/post7_12"><img src={image7_12} alt="Sample 3" /></a> 
        <a href="/post7_19"><img src={image7_19} alt="Sample 3" /></a> 
        <a href="/post7_21"><img src={image7_21} alt="Sample 3" /></a>
        <a href="/post7_28"><img src={image7_28} alt="Sample 3" /></a>  
      </div>
    </div>
  );
}

export default Photo;
