import React from 'react';
import PostFun from '../components/PostFun';
import image1 from '../img/10-7-1.jpg';
import image2 from '../img/10-7-2.jpg';

function Post10_7() {
  const title = "汤普森&索菲亚的恋爱小记录";
  const date = "2023年10月7日";
  const images = [image1,image2];
  const descriptionParagraphs = [
    "从白天到晚上的学习局，我们是可以随时切换状态的情侣。开房学习，我们既可以在床上激情酣战，也可以在桌上奋笔疾书。这也让我想起了杨绛和钱锺书一起留学的时光，互相支持，互相帮助，成就了彼此，也成就了这段美好的爱情。呜呜呜，第一次吃宝贝给我做的饭，很美味，很惊喜，很温暖，消灭干净！",
    "我们是天下第一好",
  ];

  return <PostFun title={title} date={date} imageSources={images} descriptionParagraphs={descriptionParagraphs} />;
}

export default Post10_7;