import React from 'react';
import PostFun from '../components/PostFun';
import image1 from '../img/1-1-1.jpg';
import image2 from '../img/1-1-2.jpg';

function Post1_1() {
  const title = "汤普森&索菲亚的恋爱小记录";
  const date = "2022年12月31日";
  const images = [image1,image2];
  const descriptionParagraphs = [
    "死亡谷的炽热，西雅图的严寒，烈日与大雪，激情与浪漫。星空下，暴雪中，我们的故事徐徐展开。",
  ];

  return <PostFun title={title} date={date} imageSources={images} descriptionParagraphs={descriptionParagraphs} />;
}

export default Post1_1;