import React, { useState, useEffect } from 'react';
import '../styles/Time.css';

function Time() {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = () => {
    const startDate = new Date('2022-12-31T00:00:00');
    const diff = currentTime - startDate;

    const years = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));
    const days = Math.floor(diff / (1000 * 60 * 60 * 24)) % 365;
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const seconds = currentTime.getSeconds();

    return `${years} 年 ${days} 天 ${hours} 小时 ${minutes} 分钟 ${seconds} 秒`;
  };

  return (
    <div className="time-container">
      <div className="time-top">
        <h1 className="time-title">汤普森&索菲亚的恋爱小记录</h1>
        <nav className="time-menu">
          <a href="/">苍穹首页</a>
          <a href="/photo">星汉点滴</a>
          <a href="/time">时光流星</a>
        </nav>
      </div>
      <div className="time-main-content">
        <p>始于 2022年12月31日</p>
        <p>{formatTime()}</p>
        <p>无以为期 唯心煌煌</p>
      </div>
    </div>
  );
}

export default Time;

