import React from 'react';
import PostFun from '../components/PostFun';
import image1 from '../img/7-9-1.jpg';
import image2 from '../img/7-9-2.jpg';

function Post7_9() {
  const title = "汤普森&索菲亚的恋爱小记录";
  const date = "2023年7月9日";
  const images = [image2,image1];
  const descriptionParagraphs = [
    "鹅岭二厂，复古文艺的景点，爱很美味的电影，以及那个嘎吱作响的床架",
    "想你的风还是吹到了重庆，爱你的人还是陪你到了鹅岭",
  ];

  return <PostFun title={title} date={date} imageSources={images} descriptionParagraphs={descriptionParagraphs} />;
}

export default Post7_9;