import React from 'react';
import '../styles/Post.css';

function Post({ title, date, imageSources, descriptionParagraphs }) {
  return (
    <div className="post-container"> 
      <div className="post-top">
        <h1 className="post-title">{title}</h1>
        <nav className="post-menu"> 
          <a href="/">苍穹首页</a>
          <a href="/photo">星汉点滴</a>
          <a href="/time">时光流星</a>
        </nav>
      </div>
      <div className="post-main-content">
        <h>{date}</h>
        {imageSources.map((src, index) => (
          <img key={index} src={src} alt="Description" />
        ))}
      </div>
      <div className="post-description">
        {descriptionParagraphs.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>
    </div>
  );
}

export default Post;
