import React from 'react';
import PostFun from '../components/PostFun';
import image1 from '../img/10-28-1.jpg';
import image2 from '../img/10-28-2.jpg';
import image3 from '../img/10-28-3.jpg';

function Post10_28() {
  const title = "汤普森&索菲亚的恋爱小记录";
  const date = "2023年10月28日";
  const images = [image1,image2,image3];
  const descriptionParagraphs = [
    "#大红唇美女写真",
    "#工作室写真",
    "#氛围感美女",
    "#原色调",
    "#妆容进步",
  ];

  return <PostFun title={title} date={date} imageSources={images} descriptionParagraphs={descriptionParagraphs} />;
}

export default Post10_28;