import React from 'react';
import PostFun from '../components/PostFun';
import image1 from '../img/7-28-1.jpg';
import image2 from '../img/7-28-2.jpg';
import image3 from '../img/7-28-3.jpg';

function Post7_28() {
  const title = "汤普森&索菲亚的恋爱小记录";
  const date = "2023年7月28日";
  const images = [image1,image2,image3];
  const descriptionParagraphs = [
    "近日，重庆一男子为女友制作书签，连续两日在工作台打磨，其情可颂，吾辈楷模",
  ];

  return <PostFun title={title} date={date} imageSources={images} descriptionParagraphs={descriptionParagraphs} />;
}

export default Post7_28;