import React from 'react';
import PostFun from '../components/PostFun';
import image1 from '../img/5-20-1.jpg';
import image2 from '../img/5-20-2.jpg';

function Post5_20() {
  const title = "汤普森&索菲亚的恋爱小记录";
  const date = "2023年5月20日";
  const images = [image1,image2];
  const descriptionParagraphs = [
    "520快乐！",
    "宝贝总是那么会挑礼物，欧乐智能电动牙刷，我们的蜡塑小人。宝贝很细心呀，注意到我牙龈出血问题，我会坚持使用哒。",
  ];

  return <PostFun title={title} date={date} imageSources={images} descriptionParagraphs={descriptionParagraphs} />;
}

export default Post5_20;