import React from 'react';
import PostFun from '../components/PostFun';
import image1 from '../img/7-21-1.jpg';

function Post7_21() {
  const title = "汤普森&索菲亚的恋爱小记录";
  const date = "2023年7月21日";
  const images = [image1];
  const descriptionParagraphs = [
    "宝贝在我智齿住院时就订的票，看得出来宝贝很喜欢看剧哦，这也是我第一次看推理剧，演员和氛围都好棒",
    "是的哦，演员和氛围都好棒，宝贝的手也好棒，不太老实呢，从我的袖口伸进来然后一直乱动乱摸，不过…我喜欢宝贝对我涩涩哦",
  ];

  return <PostFun title={title} date={date} imageSources={images} descriptionParagraphs={descriptionParagraphs} />;
}

export default Post7_21;