import React from 'react';
import '../styles/Post.css'; // Updated CSS import
import image1 from '../img/8-3-1.jpg';
import image2 from '../img/8-3-2.jpg';
import image4 from '../img/8-3-4.jpg';

function Post8_3() { // Updated component name
    
  return (
    <div className="post-container"> 
      <div className="post-top">
        <h1 className="post-title">汤普森&索菲亚的恋爱小记录</h1>
        <nav className="post-menu"> 
          <a href="/">苍穹首页</a>
          <a href="/photo">星汉点滴</a>
          <a href="/time">时光流星</a>
        </nav>
      </div>
      <div className="post-main-content">
        <h >2023年8月3日</h>
        <img src={image1} alt="Description of image" />
        <img src={image2} alt="Description of image" />
        <img src={image4} alt="Description of image" />
      </div>
      <div className="post-description">
        <p >给对方准备惊喜从来都不是刻意的你来我往，而是喜欢和爱的有意为之
门上挂着的一个个气球，里面充斥的快乐的空气；桌上放置的葡萄，一颗颗都是幸福的结晶；一口西瓜一口蛋糕，却都不如这爱情甜蜜；一页页手账翻开，我们是故事的主角，宝贝是用心的作家；小王子的水杯，从此不知道苦涩是什么味道；唱歌的贺卡和录制的磁带，在留声机发明的146年里，没有人比我更了解声音的形状；巴宝莉的围巾，让我们回到了死亡谷的星空下，子兴视夜，明星有烂；昏以为期，明星煌煌；徐徐而来，兰藏爱意
</p>
      </div>
    </div>
  );
}

export default Post8_3; 
