import React from 'react';
import PostFun from '../components/PostFun';
import image1 from '../img/9-1-1.jpg';

function Post9_1() {
  const title = "汤普森&索菲亚的恋爱小记录";
  const date = "2023年9月1日";
  const images = [image1];
  const descriptionParagraphs = [
    "真心何难求，的卢踏星斗",
    "好似池边柳，想尽锦瑟留",
    "宝有兰心守，贝撒天涯否",
    " ",
    "今夕香枕头，晚夜长梦柔",
    "月半泪亦愁，色浅情深透",
    "真倾鸟呦忧，美酒待逢侯",

  ];

  return <PostFun title={title} date={date} imageSources={images} descriptionParagraphs={descriptionParagraphs} />;
}

export default Post9_1;