import React from 'react';
import PostFun from '../components/PostFun';
import image1 from '../img/7-12-1.jpg';
import image2 from '../img/7-12-2.jpg';
import image3 from '../img/7-12-3.jpg';

function Post7_12() {
  const title = "汤普森&索菲亚的恋爱小记录";
  const date = "2023年7月12日";
  const images = [image1,image2,image3];
  const descriptionParagraphs = [
    "水族馆里的鱼儿依旧吐着泡泡",
    "想叫醒月亮来共赏",
    "我们这般可爱模样",
    "宝贝比我更懂浪漫，虽然水族馆很远也很热，但是我们在那里留下的幸福记忆，不会被水流冲刷，也不会被高温熔化",
  ];

  return <PostFun title={title} date={date} imageSources={images} descriptionParagraphs={descriptionParagraphs} />;
}

export default Post7_12;