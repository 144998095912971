import React from 'react';
import PostFun from '../components/PostFun';
import image1 from '../img/5-3-1.jpg';
import image2 from '../img/5-3-2.jpg';
import image3 from '../img/5-3-3.jpg';

function Post5_3() {
  const title = "汤普森&索菲亚的恋爱小记录";
  const date = "2023年5月3日";
  const images = [image1,image2,image3];
  const descriptionParagraphs = [
    "虽然没有在迪士尼看见烟花，我们却在黑夜里绽放出了属于我们自己的烟花",
    "尼克对朱迪说：“你知道你爱我”",
    "我对你说：“你知道我爱你”",
  ];

  return <PostFun title={title} date={date} imageSources={images} descriptionParagraphs={descriptionParagraphs} />;
}

export default Post5_3;