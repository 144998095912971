import React from 'react';
import PostFun from '../components/PostFun';
import image1 from '../img/4-29-1.jpg';
import image2 from '../img/4-29-2.jpg';

function Post4_29() {
  const title = "汤普森&索菲亚的恋爱小记录";
  const date = "2023年4月29日";
  const images = [image1,image2];
  const descriptionParagraphs = [
    "带着洗好的葡萄，提前做好的爱心饼干，和早晨去集市里精心挑选好的郁金香",
    "在机场看见你，奔向你，和你相拥的那一刻还是那么真诚而炽热",
  ];

  return <PostFun title={title} date={date} imageSources={images} descriptionParagraphs={descriptionParagraphs} />;
}

export default Post4_29;